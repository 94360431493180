import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
    class: "hero-video",
    autoplay: "",
    loop: "",
    muted: "",
    playsinline: ""
};
const _hoisted_2 = ["src"];
const _hoisted_3 = { class: "hero-content" };
const _hoisted_4 = { class: "hero-badge" };
const _hoisted_5 = { class: "hero-subheader z-h6" };
const _hoisted_6 = {
    key: 0,
    class: "z-b4 inter hsafsa"
};
import { ref } from "vue";
import AppIcon from "@images/AppSubscriptionLanding/AppIcon.svg";
import ZButton from "@components/Core/ZButton.vue";
import FsaHsaInformation from "./FsaHsaInformation.vue";
import { isMobileReactive, isMobile, isDesktop } from "@ts/Util/responsiveness";
import { computed } from "vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'Hero',
    props: {
        t: {
            type: Object,
            default: () => ({
                header: 'Unlock the Fun',
                subheader: 'Subscribe to Zumba Virtual+ and get unlimited access to the best on-demand Zumba classes.',
                tryItCta: 'Try it Free',
            })
        },
        videoHref: {
            type: String,
            required: true
        },
        videoMobileHref: {
            type: String,
            required: false,
            default: ''
        },
        fallbackImageHref: {
            type: String,
            required: true
        },
        fallbackImageHrefMobile: {
            type: String,
            required: true
        },
        ctaHref: {
            type: String,
            required: true
        },
        showFsaInformation: {
            type: Boolean,
            required: true
        }
    },
    setup(__props) {
        const props = __props;
        const videoSrc = computed(() => {
            const isMobile = isMobileReactive();
            return isMobile.value ? props.videoMobileHref : props.videoHref;
        });
        const fallbackImageHref = computed(() => {
            const isMobile = isMobileReactive();
            return isMobile.value ? props.fallbackImageHrefMobile : props.fallbackImageHref;
        });
        const showFsaHsaModal = ref(false);
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                _createElementVNode("section", {
                    class: "hero",
                    style: _normalizeStyle({
                        'background-image': `url(${fallbackImageHref.value})`,
                        'background-size': 'cover',
                    })
                }, [
                    _createElementVNode("video", _hoisted_1, [
                        _createElementVNode("source", {
                            src: videoSrc.value,
                            type: "video/mp4"
                        }, null, 8, _hoisted_2),
                        _cache[2] || (_cache[2] = _createTextVNode(" Your browser does not support the video tag. "))
                    ]),
                    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "overlay" }, null, -1)),
                    _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, [
                            _createVNode(_unref(AppIcon))
                        ]),
                        _createElementVNode("h2", {
                            class: _normalizeClass({ 'hero-header': true, 'z-h1': _unref(isDesktop)(), 'z-h2': _unref(isMobile)() })
                        }, _toDisplayString(__props.t.header), 3),
                        _createElementVNode("h6", _hoisted_5, _toDisplayString(__props.t.subheader), 1),
                        _createVNode(ZButton, {
                            variety: "robin",
                            href: __props.ctaHref,
                            compact: "never",
                            wide: "never",
                            class: "hero-cta"
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(__props.t.tryItCta), 1)
                            ]),
                            _: 1
                        }, 8, ["href"]),
                        (__props.showFsaInformation)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                _cache[4] || (_cache[4] = _createElementVNode("strong", null, "HSA/FSA Eligible. Save an average of 30%", -1)),
                                _createElementVNode("a", {
                                    href: "#",
                                    class: "link",
                                    "data-mixpanel-command-name": "TrackTruemedLearnMoreModalClicked",
                                    "data-mixpanel-properties": "{\"buttonType\": \"Learn More Modal\"}",
                                    "data-mixpanel-page-name": "subscription",
                                    onClick: _cache[0] || (_cache[0] = ($event) => (showFsaHsaModal.value = true))
                                }, _cache[3] || (_cache[3] = [
                                    _createElementVNode("strong", null, "Learn more", -1)
                                ]))
                            ]))
                            : _createCommentVNode("", true)
                    ])
                ], 4),
                (__props.showFsaInformation)
                    ? (_openBlock(), _createBlock(FsaHsaInformation, {
                        key: 0,
                        t: __props.t,
                        show: showFsaHsaModal.value,
                        "true-med-link": "https://app.truemed.com/survey/422cb959-6b20-4596-a3ac-94b4924f481f",
                        "faq-link": "/faq",
                        onClosed: _cache[1] || (_cache[1] = ($event) => (showFsaHsaModal.value = false))
                    }, null, 8, ["t", "show"]))
                    : _createCommentVNode("", true)
            ], 64));
        };
    }
});
