import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "from-a-to-zumba" };
const _hoisted_2 = { class: "header-section" };
const _hoisted_3 = { class: "from-zumba-title z-h3" };
const _hoisted_4 = { class: "from-zumba-subtitle z-b3 f-inter" };
const _hoisted_5 = { class: "fitness-types-section" };
const _hoisted_6 = { class: "fitness-types-container container-mobile" };
const _hoisted_7 = { class: "fitness-type-slider clearfix" };
const _hoisted_8 = { class: "fitness-types-container container-desktop" };
const _hoisted_9 = { class: "footer-section" };
const _hoisted_10 = {
    key: 0,
    class: "z-b5 f-inter trial-disclaimer"
};
import { computed } from "vue";
import ZButton from "@components/Core/ZButton.vue";
import CarouselShared from "@components/Core/CarouselShared.vue";
import FitnessTypeCard from "@components/AppSubscriptionLanding/FitnessTypeCard.vue";
import chunk from "lodash-es/chunk";
const carrouselMaxItems = 4;
export default /*@__PURE__*/ _defineComponent({
    __name: 'FromAToZumba',
    props: {
        t: {
            type: Object,
            default: () => ({
                title: 'From A to Zumba®',
                subtitle: 'Whether you want to cumbia and chill or HIIT your strength-training goals, access our on-going monthly library of workouts suited for all fitness levels',
                tryItCta: 'Try it Free',
                trialDisclaimer: 'Free trial available for a limited-time only, risk-free!'
            })
        },
        fitnessTypes: {
            type: Array,
            default: () => []
        },
        ctaHref: {
            type: String,
            required: true
        },
        hasTrialDays: {
            type: Boolean,
            default: () => true
        }
    },
    setup(__props) {
        const props = __props;
        const carrouselAmountOfSlides = computed(() => Math.ceil(props.fitnessTypes.length / carrouselMaxItems));
        const fitnessTypesChunked = chunk(props.fitnessTypes, carrouselMaxItems);
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("section", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("h3", _hoisted_3, _toDisplayString(__props.t.title), 1),
                    _createElementVNode("p", _hoisted_4, _toDisplayString(__props.t.subtitle), 1)
                ]),
                _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                        _createVNode(CarouselShared, {
                            "slider-selector": ".fitness-type-slider",
                            "slide-selector": ".fitness-type-slide",
                            "membership-type": "zumba_app",
                            "include-dot-buttons": true,
                            "include-direction-buttons": false,
                            "buttons-compacted": true,
                            interval: 7000,
                            "amount-of-slides": carrouselAmountOfSlides.value
                        }, {
                            default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_7, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(fitnessTypesChunked), (fitnessTypesChunk, indexChunk) => {
                                        return (_openBlock(), _createElementBlock("div", {
                                            key: `fitness-type-chunk-${indexChunk}`,
                                            class: "fitness-type-slide"
                                        }, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(fitnessTypesChunk, (data, index) => {
                                                return (_openBlock(), _createBlock(FitnessTypeCard, {
                                                    key: `fitness-type-mobile-${index}`,
                                                    "fitness-type": data
                                                }, null, 8, ["fitness-type"]));
                                            }), 128))
                                        ]));
                                    }), 128))
                                ])
                            ]),
                            _: 1
                        }, 8, ["amount-of-slides"])
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.fitnessTypes, (data, index) => {
                            return (_openBlock(), _createBlock(FitnessTypeCard, {
                                key: `fitness-type-desktop-${index}`,
                                "fitness-type": data
                            }, null, 8, ["fitness-type"]));
                        }), 128))
                    ])
                ]),
                _createElementVNode("div", _hoisted_9, [
                    _createVNode(ZButton, {
                        variety: "optimus",
                        href: __props.ctaHref,
                        compact: "never",
                        wide: "never",
                        class: "try-it-cta"
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(__props.t.tryItCta), 1)
                        ]),
                        _: 1
                    }, 8, ["href"]),
                    (__props.hasTrialDays)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(__props.t.trialDisclaimer), 1))
                        : _createCommentVNode("", true)
                ])
            ]));
        };
    }
});
