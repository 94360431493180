import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = { class: "meet-the-instructors" };
const _hoisted_2 = { class: "instructors-section" };
const _hoisted_3 = { class: "instructors-header-section" };
const _hoisted_4 = ["innerHTML"];
const _hoisted_5 = { class: "instructors-subtitle z-b3 f-inter" };
const _hoisted_6 = { class: "instructor-slider-controls" };
const _hoisted_7 = ["onClick"];
const _hoisted_8 = { class: "footer-section" };
import { onMounted, ref } from "vue";
import ZButton from "@components/Core/ZButton.vue";
import InstructorCard from "@components/AppSubscriptionLanding/InstructorCard.vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'MeetTheInstructors',
    props: {
        t: {
            type: Object,
            default: () => ({
                title: '<h1 class="z-h3">Meet the <strong>Instructors</strong></h1>',
                subtitle: 'From world-renowned dance pros to champion athletes and fitness experts, our team of instructors will inspire you every step of the way.',
                tryItCta: 'Try it Free',
            })
        },
        instructors: {
            type: Array,
            default: () => []
        },
        ctaHref: {
            type: String,
            required: true
        },
        interval: {
            type: Number,
            default: () => 5000
        },
    },
    setup(__props) {
        let timer;
        const props = __props;
        const flexViewportRef = ref();
        const instructorSelected = ref(0);
        onMounted(() => {
            timer = setInterval(() => goToNextSlide(), props.interval);
        });
        const goToNextSlide = () => {
            if (instructorSelected.value === props.instructors.length - 1) {
                goToSlide(0);
                return;
            }
            goToSlide(instructorSelected.value + 1);
        };
        const handleClick = (index) => {
            goToSlide(index);
            resetTimer();
        };
        const resetTimer = () => {
            clearInterval(timer);
            timer = setInterval(() => goToNextSlide(), props.interval);
        };
        const goToSlide = (index) => {
            instructorSelected.value = index;
            const card = flexViewportRef.value?.querySelector(`#instructor-card-${index}`);
            flexViewportRef.value?.scrollTo({
                left: card?.offsetLeft +
                    card?.offsetWidth / 2 - flexViewportRef.value?.offsetWidth / 2,
                behavior: 'smooth',
            });
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("section", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", {
                            class: "instructors-title",
                            innerHTML: __props.t.title
                        }, null, 8, _hoisted_4),
                        _createElementVNode("p", _hoisted_5, _toDisplayString(__props.t.subtitle), 1)
                    ]),
                    _createElementVNode("div", {
                        ref_key: "flexViewportRef",
                        ref: flexViewportRef,
                        class: "instructors-container"
                    }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.instructors, (data, index) => {
                            return (_openBlock(), _createBlock(InstructorCard, {
                                id: `instructor-card-${index}`,
                                key: `instructor-slide-${index}`,
                                instructor: data
                            }, null, 8, ["id", "instructor"]));
                        }), 128))
                    ], 512),
                    _createElementVNode("ol", _hoisted_6, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.instructors, (data, index) => {
                            return (_openBlock(), _createElementBlock("li", {
                                key: `instructor-button-${index}`
                            }, [
                                _createElementVNode("a", {
                                    class: _normalizeClass({
                                        'active': instructorSelected.value === index
                                    }),
                                    onClick: ($event) => (handleClick(index))
                                }, _toDisplayString(index + 1), 11, _hoisted_7)
                            ]));
                        }), 128))
                    ])
                ]),
                _createElementVNode("div", _hoisted_8, [
                    _createVNode(ZButton, {
                        variety: "robin",
                        href: __props.ctaHref,
                        compact: "never",
                        wide: "never",
                        class: "try-it-cta"
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(__props.t.tryItCta), 1)
                        ]),
                        _: 1
                    }, 8, ["href"])
                ])
            ]));
        };
    }
});
