import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "subscription-section-common" };
const _hoisted_2 = { class: "ayp-container" };
const _hoisted_3 = { class: "ayp-content" };
const _hoisted_4 = { class: "ayp-header-section" };
const _hoisted_5 = { class: "mobile-only z-h3 ayp-header" };
const _hoisted_6 = { class: "bigger-than-mobile z-h1 ayp-header" };
const _hoisted_7 = { class: "mobile-only z-h3 ayp-header" };
const _hoisted_8 = { class: "bigger-than-mobile z-h1 ayp-header" };
const _hoisted_9 = ["innerHTML"];
import ZButton from '@components/Core/ZButton.vue';
const localT = {
    header1: 'At your pace.',
    header2: 'At your place.',
    subheader: 'Go from beginner to booty-shaker with workouts designed to help you get the most out of your fitness routine.',
    downloadButton: 'Download',
};
export default /*@__PURE__*/ _defineComponent({
    __name: 'AtYourPlace',
    props: {
        t: {
            type: Object,
            default: () => { localT; },
        },
        downloadUrl: {
            type: String,
            required: true,
        },
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("section", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                        _cache[0] || (_cache[0] = _createElementVNode("p", { class: "ayp-section-name z-b3 f-inter" }, " Zumba® Virtual+ ", -1)),
                        _createElementVNode("div", _hoisted_4, [
                            _createElementVNode("h3", _hoisted_5, _toDisplayString(__props.t.header1), 1),
                            _createElementVNode("h1", _hoisted_6, _toDisplayString(__props.t.header1), 1),
                            _createElementVNode("h3", _hoisted_7, _toDisplayString(__props.t.header2), 1),
                            _createElementVNode("h1", _hoisted_8, _toDisplayString(__props.t.header2), 1)
                        ]),
                        _createElementVNode("div", {
                            class: "ayp-description",
                            innerHTML: __props.t.subheader
                        }, null, 8, _hoisted_9),
                        _createVNode(ZButton, {
                            class: "z-b5 ayp-download-button",
                            label: __props.t.download,
                            disabled: false,
                            href: __props.downloadUrl,
                            wide: 'never'
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(props.t.downloadButton), 1)
                            ]),
                            _: 1
                        }, 8, ["label", "href"])
                    ]),
                    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "ayp-image" }, [
                        _createElementVNode("img", {
                            src: "https://d29za44huniau5.cloudfront.net/uploads/2024/3/at_your_pace_graphics.svg",
                            alt: "at_your_pace_graphics"
                        })
                    ], -1))
                ])
            ]));
        };
    }
});
