import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "ryr-section" };
const _hoisted_2 = { class: "ryr-wording" };
const _hoisted_3 = { class: "ryr-content" };
const _hoisted_4 = { class: "ryr-header-section" };
const _hoisted_5 = { class: "mobile-only z-h3 ryr-header" };
const _hoisted_6 = { class: "bigger-than-mobile z-h1 ryr-header" };
const _hoisted_7 = ["innerHTML"];
const _hoisted_8 = { class: "ryr-features" };
const _hoisted_9 = { class: "mobile-only" };
const _hoisted_10 = { class: "ryr-slider" };
const _hoisted_11 = ["src", "alt"];
const _hoisted_12 = { class: "z-h6 f-inter ryr-section-name" };
const _hoisted_13 = { class: "z-b3 f-inter" };
const _hoisted_14 = { class: "bigger-than-mobile" };
const _hoisted_15 = { class: "ryr-slider" };
const _hoisted_16 = ["src", "alt"];
const _hoisted_17 = { class: "z-h6 f-inter ryr-section-name" };
const _hoisted_18 = { class: "z-b3 f-inter" };
import ZButton from '@components/Core/ZButton.vue';
import CarouselShared from "@components/Core/CarouselShared.vue";
const localT = {
    header1: 'Remix Your Routine',
    subheader: 'From beginners dance tutorials to high-intensity interval training, there\'s a workout for every body.',
    tryItButton: 'Try it free',
};
export default /*@__PURE__*/ _defineComponent({
    __name: 'RemixYourRoutine',
    props: {
        t: {
            type: Object,
            default: () => { localT; },
        },
        features: {
            type: Array,
            default: () => [],
        },
        tryItUrl: {
            type: String,
            required: true,
        },
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, [
                            _createElementVNode("h3", _hoisted_5, _toDisplayString(__props.t.header1), 1),
                            _createElementVNode("h1", _hoisted_6, _toDisplayString(__props.t.header1), 1)
                        ]),
                        _createElementVNode("div", {
                            class: "ryr-description",
                            innerHTML: __props.t.subheader
                        }, null, 8, _hoisted_7),
                        _createVNode(ZButton, {
                            class: "z-b5 ryr-download-button",
                            label: __props.t.tryItButton,
                            disabled: false,
                            href: __props.tryItUrl,
                            wide: 'never'
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(props.t.tryItButton), 1)
                            ]),
                            _: 1
                        }, 8, ["label", "href"])
                    ]),
                    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "ryr-image bigger-than-mobile" }, [
                        _createElementVNode("img", {
                            src: "https://d29za44huniau5.cloudfront.net/uploads/2024/3/Image-Module2.svg",
                            alt: "at_your_pace_graphics"
                        })
                    ], -1))
                ]),
                _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                        _createVNode(CarouselShared, {
                            "slide-selector": ".ryr-slider-item",
                            "slider-selector": ".ryr-slider",
                            "include-dot-buttons": true,
                            "include-direction-buttons": false,
                            interval: 7000,
                            "amount-of-slides": __props.features.length,
                            "item-width": 382
                        }, {
                            default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_10, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.features, (feature) => {
                                        return (_openBlock(), _createElementBlock("div", {
                                            key: feature.title,
                                            class: "ryr-slider-item"
                                        }, [
                                            _createElementVNode("img", {
                                                class: "ryr-feature-image",
                                                src: feature.iconUrl,
                                                alt: feature.title
                                            }, null, 8, _hoisted_11),
                                            _createElementVNode("h6", _hoisted_12, _toDisplayString(feature.title), 1),
                                            _createElementVNode("p", _hoisted_13, _toDisplayString(feature.description), 1)
                                        ]));
                                    }), 128))
                                ])
                            ]),
                            _: 1
                        }, 8, ["amount-of-slides"])
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("div", _hoisted_15, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.features, (feature) => {
                                return (_openBlock(), _createElementBlock("div", {
                                    key: feature.title,
                                    class: "ryr-slider-item"
                                }, [
                                    _createElementVNode("img", {
                                        class: "ryr-feature-image",
                                        src: feature.iconUrl,
                                        alt: feature.title
                                    }, null, 8, _hoisted_16),
                                    _createElementVNode("h6", _hoisted_17, _toDisplayString(feature.title), 1),
                                    _createElementVNode("p", _hoisted_18, _toDisplayString(feature.description), 1)
                                ]));
                            }), 128))
                        ])
                    ])
                ])
            ]));
        };
    }
});
