import { defineComponent, createApp } from 'vue/dist/vue.esm-bundler';
import Hero from '@modules/AppSubscriptionLanding/Hero.vue';
import EasyAsOneTwoThree from '@modules/AppSubscriptionLanding/EasyAsOneTwoThree.vue';
import TrustPilotReviews from '@modules/AppSubscriptionLanding/TrustPilotReviews.vue';
import FAQs from '@modules/AppSubscriptionLanding/FAQs.vue';
import JoinFree from '@modules/AppSubscriptionLanding/JoinFree.vue';
import FromAToZumba from '@modules/AppSubscriptionLanding/FromAToZumba.vue';
import MeetTheInstructors from '@modules/AppSubscriptionLanding/MeetTheInstructors.vue';
import AtYourPlace from '@modules/AppSubscriptionLanding/AtYourPlace.vue';
import RemixYourRoutine from '@modules/AppSubscriptionLanding/RemixYourRoutine.vue';
import PromoBanner from '@modules/AppSubscriptionLanding/PromoBanner.vue';
import { onMounted, ref } from 'vue';
import { readPageData } from '@ts/Util/page';
import { readCookie } from '@ts/Util/cookies';
import { post } from "@ts/Util/http";
import { currentUserLocale } from "@ts/Util/i18n";
import { csrfToken } from "@ts/Util/security";
const CHECK_INTERVAL = 1000;
const MAX_CHECK_TIME = 60000;
const ZumbaAppSubscription = defineComponent({
    name: 'ZumbaAppSubscription',
    components: {
        Hero,
        EasyAsOneTwoThree,
        TrustPilotReviews,
        FAQs,
        JoinFree,
        FromAToZumba,
        MeetTheInstructors,
        AtYourPlace,
        RemixYourRoutine,
        PromoBanner
    },
    setup() {
        const oneLinkURL = ref('#join-free');
        const storeLinks = ref('');
        const showQrCode = ref(false);
        let Swivel;
        let checkInterval;
        let startTime;
        const checkAFSmartScriptResult = () => {
            if (window.AF_SMART_SCRIPT_RESULT && typeof window.AF_SMART_SCRIPT_RESULT !== undefined) {
                if (Swivel.invoke('CAUTH.AppSubscriptionLanding.AppFlyerLinks', true, false)) {
                    oneLinkURL.value = window.AF_SMART_SCRIPT_RESULT.clickURL;
                    showQrCode.value = true;
                    storeLinks.value = oneLinkURL.value;
                    window.AF_SMART_SCRIPT.displayQrCode("qr-code-rendered");
                    clearInterval(checkInterval);
                }
            }
        };
        const startChecking = () => {
            startTime = Date.now();
            checkInterval = setInterval(() => {
                checkAFSmartScriptResult();
                if (Date.now() - startTime > MAX_CHECK_TIME) {
                    clearInterval(checkInterval);
                }
            }, CHECK_INTERVAL);
        };
        const locale = currentUserLocale() ?? 'en-US';
        const sendInviteCode = async (code) => await post(`/${locale}/ConsumerApp/consumer_app_invite`, csrfToken(), {
            invite_code: code
        });
        onMounted(async () => {
            Swivel = new window.Swivel({
                map: readPageData('swivelMap'),
                bucketIndex: readCookie('Swivel_Bucket'),
            });
            startChecking();
            let deepLinkValue = (new URLSearchParams(window.location.search)).get('deep_link_value');
            if (deepLinkValue) {
                try {
                    const response = await sendInviteCode(deepLinkValue);
                    if (!response.ok) {
                        return;
                    }
                }
                catch (error) {
                    return;
                }
            }
        });
        return {
            oneLinkURL,
            showQrCode,
            storeLinks
        };
    },
});
createApp(ZumbaAppSubscription).mount('#subscription-container');
