import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = { class: "easy-as-one-two-three" };
const _hoisted_2 = { class: "container" };
const _hoisted_3 = { class: "devices-section" };
const _hoisted_4 = ["src"];
const _hoisted_5 = { class: "brand-section" };
const _hoisted_6 = ["innerHTML"];
const _hoisted_7 = { class: "easy-subtitle z-b3 f-inter" };
const _hoisted_8 = {
    key: 0,
    class: "tv-providers-section"
};
const _hoisted_9 = ["src", "alt"];
import ZButton from "@components/Core/ZButton.vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'EasyAsOneTwoThree',
    props: {
        t: {
            type: Object,
            default: () => ({
                title: 'Easy as 1-2-3!',
                subtitle: 'Stream classes to your SmartTV or take the party on the go with your phone, laptop, or tablet - all included in your membership.',
                tryItCta: 'Try it Free',
            })
        },
        devicesImageHref: {
            type: String,
            required: true,
        },
        tvProviders: {
            type: Array,
            default: () => []
        },
        ctaHref: {
            type: String,
            required: true
        },
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("section", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("img", {
                            src: __props.devicesImageHref,
                            alt: "Devices"
                        }, null, 8, _hoisted_4)
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", {
                            class: "easy-title",
                            innerHTML: __props.t.title
                        }, null, 8, _hoisted_6),
                        _createElementVNode("p", _hoisted_7, _toDisplayString(__props.t.subtitle), 1),
                        (__props.tvProviders.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.tvProviders, ({ url, alt }) => {
                                    return (_openBlock(), _createElementBlock("img", {
                                        key: url,
                                        src: url,
                                        alt: alt || '#'
                                    }, null, 8, _hoisted_9));
                                }), 128))
                            ]))
                            : _createCommentVNode("", true),
                        _createVNode(ZButton, {
                            variety: "robin",
                            href: __props.ctaHref,
                            compact: "never",
                            wide: "never",
                            class: "try-it-cta"
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(__props.t.tryItCta), 1)
                            ]),
                            _: 1
                        }, 8, ["href"])
                    ])
                ])
            ]));
        };
    }
});
